import React, { useState} from 'react';
import { Launcher } from 'react-chat-window';
import axios from '../api/axios';
import "../assets/css/chatWindow.css";
import useAuth from '../hooks/useAuth';
import LoadingChat from './LoadingChat';

const ChatWindow = () => {
  const { auth } = useAuth();
  const { identificadorAplicativoWeb, usuario, cnpj} = auth;

  const initialMessage = [
    {
      author: 'Chat', 
      type: 'text', 
      data: {
        text: 'Olá! Envie uma mensagem para começar.',
      },
    },
  ]; 
  const [messageList, setMessageList] = useState(initialMessage);
  const [digitando, setDigitando] = useState(false);
  const [conversaIdAPI, setConversaIdAPI] = useState(null);

  const _onMessageWasSent = async (message) => {
    try {

      setMessageList((prevMessages) => [...prevMessages, message]);
      setDigitando(true);

      const body = { 
        cnpjLoja: cnpj,
        usuarioCodigoExterno: usuario,
        mensagem: message.data.text,
      }

      const bodyContinuaConversa = { 
        ...body,
        conversa_id: conversaIdAPI
      }
    
const response = conversaIdAPI != null
  ? await axios.post(`/chat/conversa/${identificadorAplicativoWeb}`, bodyContinuaConversa)
  : await axios.post(`/chat/conversa/${identificadorAplicativoWeb}`, body);
    
      const mensagem = response.data.resposta;
      const mensagemTratada = mensagem.replace('# --------------------------- #', '');
     
      if (response.data) {
        const serverResponseMessage = {
          author: 'Chat',
          type: 'text',
          data: { text: mensagemTratada || 'Algo inesperado aconteceu ao processar sua solicitação... tente novamente ou entre em contato com a Toolspharma' },
        };
        setMessageList((prevMessages) => [...prevMessages, serverResponseMessage]);
        setConversaIdAPI(response.data.conversa_id);
      } else {
        console.warn('A resposta da API não contém dados.');
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      alert('Erro ao enviar mensagem. Tente novamente ou entre em contato com a Toolspharma.');
    } finally {
      setDigitando(false);
    }

  }
  return (
    <div>
      <Launcher
        agentProfile={{
          teamName: 'Chat',
          imageUrl: 'https://toolspharma.com.br/wp-content/uploads/2025/01/Favicon-1-e1736965311556.png',
        }}
        onMessageWasSent={_onMessageWasSent}
        messageList={
          digitando
            ? [
              ...messageList,
              { author: 'Chat', type: 'text', data: { text: <LoadingChat /> } },
            ]
            : messageList
        }
        showEmoji={false}
      />
    </div>
  );
};

export default ChatWindow;
